define('embercasts/routes/about', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),

    afterModel: function afterModel(model) {
      this.get('headData').set('ogDescription', 'Created by Prototypal, Embercasts provides online training from the leading experts in Ember.js application development.');

      var currentUrl = void 0;
      if (this.get('isFastBoot')) {
        var req = this.get('fastboot.request').getProperties('protocol', 'host', 'path');
        currentUrl = req.protocol + '//' + req.host + req.path;
      } else {
        currentUrl = window.location.href;
      }

      this.get('headData').set('ogURL', currentUrl);
    }
  });
});