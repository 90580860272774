define('embercasts/components/form-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',

    submit: function submit(ev) {
      ev.preventDefault();

      this.get('onsubmit')();
    }
  });
});