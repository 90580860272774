define('embercasts/router', ['exports', 'embercasts/config/environment', 'ember-tracker/mixins/google-analytics-route', 'ember-router-scroll'], function (exports, _environment, _googleAnalyticsRoute, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_googleAnalyticsRoute.default, _emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.get('metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    if (_environment.default.environment !== 'production') {
      this.route('styleguide');
    }

    this.route('video', function () {
      this.route('detail', {
        path: '/:id'
      });
    });

    this.route('free-video');

    this.route('not-found', {
      path: '*path'
    });
    this.route('signup');
    this.route('login', function () {
      this.route('verify');
    });
    this.route('course-preview', { path: '/course-preview/:courseId' }, function () {
      this.route('watch', { path: 'watch/:videoId' });
      this.route('purchase');
    });

    this.route('course', { path: '/course/:courseId' }, function () {
      this.route('watch', { path: 'watch/:videoId' });
      this.route('purchase');
    });
    this.route('account');
    this.route('privacy-policy');
    this.route('terms-of-service');
    this.route('courses');
    this.route('about');
  });

  exports.default = Router;
});