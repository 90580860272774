define('embercasts/components/courses-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    courses: Ember.computed('currentCourseId', function () {
      var allCourses = this.get('store').peekAll('course').toArray();
      var currentCourseId = this.get('currentCourseId');
      return allCourses.filter(function (course) {
        return course.get('id') !== currentCourseId;
      });
    })
  });
});