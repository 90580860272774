define('embercasts/helpers/cents-to-dollars', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.centsToDollars = centsToDollars;
  function centsToDollars(value) {
    var dollars = Math.floor(value / 100);
    var cents = value % 100;

    if (cents.toString().length === 1) {
      cents = '0' + cents;
    }

    return '' + dollars;
  }

  exports.default = Ember.Helper.helper(centsToDollars);
});