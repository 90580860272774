define('embercasts/authenticators/knock', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jwt.default.extend({
    getAuthenticateData: function getAuthenticateData(credentials) {
      var authentication = {
        auth: {
          email: credentials.identification,
          password: credentials.password
        }
      };

      return authentication;
    },
    authenticate: function authenticate(credentials) {
      if (credentials.jwt) {
        var sessionData = this.handleAuthResponse({ jwt: credentials.jwt });

        return Promise.resolve(sessionData);
      }

      return this._super.apply(this, arguments);
    }
  });
});