define('embercasts/controllers/course/watch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    theatreMode: false,

    actions: {
      toggleTheatreMode: function toggleTheatreMode() {
        this.toggleProperty('theatreMode');
        return true;
      },
      playNext: function playNext(video) {
        this.transitionToRoute('course.watch', video.get('id'));
      }
    }
  });
});