define('embercasts/components/link-to-wrapper', ['exports', 'ember-link-to-wrapper/components/link-to-wrapper'], function (exports, _linkToWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkToWrapper.default;
    }
  });
});