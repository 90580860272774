define('embercasts/models/video', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    videoUrl: attr(),
    placeholderUrl: attr(),
    videoLength: attr(),
    author: attr(),
    title: attr(),
    position: attr('number'),
    available: attr('boolean'),
    description: attr(),
    shortDescription: attr(),
    createdAt: attr('date'),
    course: belongsTo('course'),
    videoProgresses: hasMany('video-progress')
  });
});