define('embercasts/adapters/application', ['exports', 'ember-data', 'embercasts/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  exports.default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',

    host: _environment.default.DS.host
  });
});