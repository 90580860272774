define('embercasts/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    sessionAuthenticated: function sessionAuthenticated() {
      var attemptedTransition = this.get('session.attemptedTransition');
      var cookies = Ember.getOwner(this).lookup('service:cookies');
      var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');
      var purchaseAttempt = this.get('session.purchaseAttempt');

      if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else if (purchaseAttempt) {
        this.set('session.purchaseAttempt', false);
      } else {
        this.transitionTo('index');
      }
    }
  });
});