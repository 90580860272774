define('embercasts/helpers/sec-to-human', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.secToHuman = secToHuman;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Helper = Ember.Helper;


  var getUnit = function getUnit(duration, unit, existing) {
    var value = duration[unit + 's']();

    if (value === 1) {
      return value + ' ' + unit + ' ' + existing;
    } else if (value > 1) {
      return value + ' ' + unit + 's ' + existing;
    } else {
      return existing;
    }
  };

  function secToHuman(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        seconds = _ref2[0];

    var duration = _moment.default.duration(seconds, 'seconds');
    var result = '';

    result = getUnit(duration, 'minute', result);
    result = getUnit(duration, 'hour', result);

    return result;
  }

  exports.default = Helper.helper(secToHuman);
});