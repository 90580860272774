define('embercasts/routes/courses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),

    model: function model() {
      return this.get('store').findAll('course', {
        include: 'videos'
      });
    },
    afterModel: function afterModel(model) {
      this.get('headData').set('ogDescription', 'Choose from several Ember.js courses and learn to build a full stack Ember application with a real JSONAPI compliant backend, whether it be Node.js, Ruby on Rails or Elixir Phoenix.');

      var currentUrl = void 0;
      if (this.get('isFastBoot')) {
        var req = this.get('fastboot.request').getProperties('protocol', 'host', 'path');
        currentUrl = req.protocol + '//' + req.host + req.path;
      } else {
        currentUrl = window.location.href;
      }

      this.get('headData').set('ogURL', currentUrl);
    }
  });
});