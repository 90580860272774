define('embercasts/services/google-analytics', ['exports', 'ember-tracker/services/google-analytics'], function (exports, _googleAnalytics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleAnalytics.default;
    }
  });
});