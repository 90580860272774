define('embercasts/components/top-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNameBindings: ['menuOpen'],
    menuOpen: false,

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      toggleMenu: function toggleMenu() {
        var html = document.querySelector('html');
        var body = document.querySelector('body');
        this.toggleProperty('menuOpen');
        html.classList.toggle('modal-open--body');
        body.classList.toggle('modal-open--body');
      },
      closeMenu: function closeMenu() {
        var html = document.querySelector('html');
        var body = document.querySelector('body');
        this.set('menuOpen', false);
        html.classList.remove('modal-open--body');
        body.classList.remove('modal-open--body');
      }
    }
  });
});