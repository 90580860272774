define('embercasts/routes/free-video', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),

    model: function model() {
      return this.get('store').findAll('course', {
        include: 'videos'
      });
    },
    afterModel: function afterModel(model) {
      this.get('headData').set('ogDescription', 'Learn how to Build a Trello Clone in 20 minutes using Ember.js');
    }
  });
});