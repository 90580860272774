define('embercasts/components/payment-request-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    googleAnalytics: Ember.inject.service(),
    stripev3: Ember.inject.service(),

    paymentRequest: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var paymentRequest = this.get('paymentRequest');

      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(function (result) {
        if (result) {
          var stripe = _this.get('stripev3');
          var elements = stripe.elements();
          _this.paymentRequestButton = elements.create('paymentRequestButton', {
            paymentRequest: paymentRequest
          });

          _this.paymentRequestButton.mount('#payment-request-button');
          document.getElementById('payment-request-copy').style.display = 'block';
        } else {
          document.getElementById('payment-request-button').style.display = 'none';
          document.getElementById('payment-request-copy').style.display = 'none';
        }
      });
    }
  });
});