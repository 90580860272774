define('embercasts/models/purchase', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    stripeToken: attr(),
    company: attr(),
    email: attr(),
    cost: attr(),
    jwt: attr(),
    createdAt: attr(),
    courses: hasMany()
  });
});