define('embercasts/models/video-progress', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    seconds: attr('number'),
    percent: attr('number'),
    video: belongsTo('video'),
    user: belongsTo('user')
  });
});