define('embercasts/models/course', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    title: attr(),
    placeholderUrl: attr(),
    description: attr(),
    shortDescription: attr(),
    secondaryDescription: attr(),
    cost: attr(),
    secondary_cost: attr(),
    seconds: attr(),
    comingSoon: attr('boolean'),
    purchased: attr('boolean'),
    videos: hasMany('video'),

    videoSorting: Object.freeze(['position']),
    sortedVideos: Ember.computed.sort('videos', 'videoSorting'),

    nextVideo: function nextVideo(prev) {
      var sortedVideos = this.get('sortedVideos');
      var indexOfPrev = sortedVideos.indexOf(prev);

      return sortedVideos[indexOfPrev + 1];
    }
  });
});