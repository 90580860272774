define('embercasts/routes/unindexed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    headData: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);
      this.get('headData').set('noindex', true);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.get('headData').set('noindex', false);
    }
  });
});